import './scss/app.scss'

//// MODULE IMPORTS

/// Swiper
// core styles
import 'swiper/css'

// module styles
import 'swiper/css/navigation'
import 'swiper/css/pagination'

// js
import Swiper, {Autoplay, Navigation, Pagination} from 'swiper'

Swiper.use([Autoplay, Navigation, Pagination])

/// Plyr
import Plyr from 'plyr';

/// GSAP
import {CSSPlugin, Expo, gsap, Linear} from 'gsap'
import {ScrollToPlugin} from 'gsap/ScrollToPlugin'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, CSSPlugin, Expo, Linear);

/// locomotive-scroll
import LocomotiveScroll from 'locomotive-scroll';

/// splitting.js
import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';
import Splitting from "splitting";

//// CODE BELOW

// Mobile menu
document.addEventListener('DOMContentLoaded', () => {
    const toggler = document.querySelector('.navbar-menu-toggler'),
        menu = document.querySelector('.navbar-menu'),
        body = document.body;

    let a, b;

    function toggleMenu() {
        var that = this;

        clearTimeout(b);

        if (!menu.classList.contains('menu-open')) {
            body.classList.add('menu-is-open');
            menu.style.display = 'flex';
            setTimeout(function () {
                menu.classList.add('menu-open');
            }, 50);
        } else {
            body.classList.remove('menu-is-open');
            menu.classList.remove('menu-open');
            b = setTimeout(function () {
                menu.style.display = '';
            }, 650);
        }
    }

    if (toggler) {
        toggler.addEventListener('click', toggleMenu, false);
    }
});

// Locomotive Scroll
document.addEventListener('DOMContentLoaded', () => {
    const video = document.querySelector('.video-scale');

    const locoScroll = new LocomotiveScroll({
        el: document.querySelector('#page-wrapper'),
        smooth: true,
        multiplier: 0.5,
    });

    // each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
    locoScroll.on('scroll', ScrollTrigger.update);

    //filter based on property type
    if(document.querySelector('#btnFilterSearch')){
        const filterBtn = document.querySelector('#btnFilterSearch');
        filterBtn.addEventListener("click", function (e) {
            e.preventDefault();
            // var country = $('#countrySelect').find("option:selected").val();
            var property = $('#propertySelect').find("option:selected").val();
            SearchPropery(property);
            // console.log(property.toLowerCase());
            if (property.toLowerCase() != 'all'){
                $('#propertyResults').show();
            }
        });
        filterBtn.addEventListener('click', () => locoScroll.update());
        var property = $('#propertySelect').find("option:selected").val();
        // console.log(property.toLowerCase());
        if (property.toLowerCase() == 'all') {
            $('#propertyResults').hide();
        }
        function SearchPropery(property) {
            if (property.toLowerCase() == 'all') {
                $('#propertyResults').hide();
            } else {
                $('#propertyResults div:has(p)').each(function () {
                    var rowProperty = $.trim($(this).find('p:eq(0)').text());
                    if (property.toLowerCase() != 'all') {
                        if (rowProperty.toLowerCase() == property.toLowerCase()) {
                            $(this).show();
                        } else {
                            $(this).hide();
                        }
                    }
                });
            }
        }
    }

    // tell ScrollTrigger to use these proxy methods for the "smooth scrolling" element since Locomotive Scroll is hijacking things
    ScrollTrigger.scrollerProxy('#page-wrapper', {
        scrollTop(value) {
            return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
        }, // we don't have to define a scrollLeft because we're only scrolling vertically.
        getBoundingClientRect() {
            return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
        },
        // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
        pinType: document.querySelector('#page-wrapper').style.transform ? 'transform' : 'fixed'
    });

    function init() {
        if (window.innerWidth > 767) {
            let tlVideo = gsap.timeline({
                paused: true,
                scrollTrigger: {
                    trigger: '.teaser-section',
                    scroller: '#page-wrapper',
                    scrub: true,
                    start: 'center center',
                    end: '=+150%'
                }
            });

            tlVideo.to(video, {css: {scale: 1}}, 1)
        }
    }

    init();

    // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll.
    ScrollTrigger.addEventListener('refresh', () => locoScroll.update());

    // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
    ScrollTrigger.refresh();
});

// Video controls
document.addEventListener('DOMContentLoaded', () => {
    const video = document.querySelector('video');
    const volume = document.querySelector('.video-volume');

    function volumeToggle() {
        if (!this.previousElementSibling.children[0].muted) {
            this.previousElementSibling.children[0].volume = 0
            this.previousElementSibling.children[0].muted = true
            this.children[0].style.display = '';
            this.children[1].style.display = '';
        } else {
            this.previousElementSibling.children[0].volume = 1
            this.previousElementSibling.children[0].muted = false
            this.children[0].style.display = 'none';
            this.children[1].style.display = 'block';
        }
    }

    if (video) {
        video.addEventListener('loadeddata', (event) => {
            console.log(event.target.muted);
            if (!event.target.muted) {
                event.target.parentNode.nextElementSibling.children[0].style.display = 'none';
                event.target.parentNode.nextElementSibling.children[1].style.display = 'block';
            } else {
                event.target.parentNode.nextElementSibling.children[0].style.display = '';
                event.target.parentNode.nextElementSibling.children[1].style.display = '';
            }
        });

        volume.addEventListener('click', volumeToggle, false)
    }
});

// Load single div backgrounds
document.addEventListener('DOMContentLoaded', () => {
    var image = document.querySelectorAll('img[data-bg="true"], [data-bg="true"]');

    for (var i = 0; i < image.length; i++) {
        (function (n) {
            var img = new Image();

            img.onload = imageFound;
            img.onerror = imageNotFound;
            img.src = image[n].src;

            function imageFound() {
                image[n].parentNode.style.backgroundImage = 'url(' + img.src + ')';

                if ($(image[n]).parent().is('.bg')) {
                    $(image[n]).parent().css({
                        opacity: '1'
                    });
                }
            }

            function imageNotFound() {
                image[n].parentNode.style.backgroundColor = '#F8F9FD';
            }
        }(i));
    }
});

// Swiper - Home
document.addEventListener('DOMContentLoaded', () => {
    const swiperContainer = document.querySelector('.swiper-home')
    const interleaveOffset = 0.5

    if (swiperContainer) {
        const swiper = new Swiper(swiperContainer, {
            speed: 1400,
            slidesPerView: 1,
            autoplay: {
                delay: 3000,
            },
            watchSlidesProgress: true,
            navigation: {
                prevEl: '.swiper-home .swiper-btn-prev',
                nextEl: '.swiper-home .swiper-btn-next',
            },
            pagination: {
                el: '.swiper-home .swiper-pagination',
                type: 'fraction',
                formatFractionCurrent: function (number) {
                    return ('0' + number).slice(-2);
                },
                formatFractionTotal: function (number) {
                    return ('0' + number).slice(-2);
                },
                renderFraction: function (currentClass, totalClass) {
                    return '<span class="' + currentClass + '"></span>' + ' / ' + '<span class="' + totalClass + '"></span>';
                }
            },
            on: {
                progress: function (s, progress) {
                    let swiper = this;

                    for (var i = 0; i < swiper.slides.length; i++) {
                        var slideProgress = swiper.slides[i].progress;
                        var innerOffset = swiper.width * interleaveOffset;
                        var innerTranslate = slideProgress * innerOffset;
                        swiper.slides[i].querySelector('.hero-media').style.transform = 'translate3d(' + innerTranslate + 'px, 0, 0)';
                    }
                },
                setTransition: function (s, transition) {
                    let swiper = this;

                    for (var i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].querySelector('.hero-media').style.transition = `transform ${transition}ms ease`;
                    }
                },
                slideChangeTransitionStart: function (swiper) {
                    swiper.slides[swiper.realIndex].querySelector('.hero-media').style.transformOrigin = '0% 50% 0';
                    swiper.slides[swiper.previousIndex].querySelector('.hero-media').style.transformOrigin = '100% 50% 0';
                },
            },
            breakpoints: {
                768: {
                    noSwipingClass: '.swiper-home',
                }
            }
        })
    }
});

// Swiper - Case studies
document.addEventListener('DOMContentLoaded', () => {
    const swiperContainer = document.querySelector('.swiper-case-studies')
    const interleaveOffset = 0.5

    if (swiperContainer) {
        const swiper = new Swiper(swiperContainer, {
            speed: 1400,
            slidesPerView: 1,
            watchSlidesProgress: true,
            noSwipingClass: '.swiper-case-studies',
            navigation: {
                prevEl: '.swiper-case-studies .swiper-btn-prev',
                nextEl: '.swiper-case-studies .swiper-btn-next',
            },
            pagination: {
                el: '.swiper-case-studies .swiper-pagination',
                type: 'fraction',
                formatFractionCurrent: function (number) {
                    return ('0' + number).slice(-2);
                },
                formatFractionTotal: function (number) {
                    return ('0' + number).slice(-2);
                },
                renderFraction: function (currentClass, totalClass) {
                    return '<span class="' + currentClass + '"></span>' + ' / ' + '<span class="' + totalClass + '"></span>';
                }
            },
            on: {
                progress: function (s, progress) {
                    let swiper = this;

                    for (var i = 0; i < swiper.slides.length; i++) {
                        var slideProgress = swiper.slides[i].progress;
                        var innerOffset = swiper.width * interleaveOffset;
                        var innerTranslate = slideProgress * innerOffset;
                        swiper.slides[i].querySelector('.case-media').style.transform = 'translate3d(' + innerTranslate + 'px, 0, 0)';
                    }
                },
                setTransition: function (s, transition) {
                    let swiper = this;

                    for (var i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].querySelector('.case-media').style.transition = `transform ${transition}ms ease`;
                    }
                },
                slideChangeTransitionStart: function (swiper) {
                    swiper.slides[swiper.realIndex].querySelector('.case-media').style.transformOrigin = '0% 50% 0';
                    swiper.slides[swiper.previousIndex].querySelector('.case-media').style.transformOrigin = '100% 50% 0';
                },
            }
        })
    }
});

// Swiper - Team
document.addEventListener('DOMContentLoaded', () => {
    const swiperContainer = document.querySelector('.swiper-team')

    if (swiperContainer) {
        const swiper = new Swiper(swiperContainer, {
            speed: 600,
            slidesPerView: 1.4,
            spaceBetween: 20,
            noSwipingClass: '.swiper-team',
            navigation: {
                prevEl: '.swiper-team .swiper-btn-prev',
                nextEl: '.swiper-team .swiper-btn-next',
            },
            breakpoints: {
                480: {
                    slidesPerView: 1.4,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2.4,
                    spaceBetween: 30,
                },
                1024: {
                    slidesPerView: 3.4,
                    spaceBetween: 30,
                },
            }
        })
    }
});

// Play video in modal
document.addEventListener('DOMContentLoaded', () => {
    let player;
    let element = document.querySelectorAll('[data-modal-video]');
    let modalEl;
    let modalClose;
    let body = document.body;
    let a, b;

    var support = (function () {
        if (!window.DOMParser) return false;
        var parser = new DOMParser();
        try {
            parser.parseFromString('x', 'text/html');
        } catch (err) {
            return false;
        }
        return true;
    })();

    var stringToHTML = function (str) {

        // If DOMParser is supported, use it
        if (support) {
            var parser = new DOMParser();
            var doc = parser.parseFromString(str, 'text/html');
            return doc.body;
        }

        // Otherwise, fallback to old-school method
        var dom = document.createElement('div');
        dom.innerHTML = str;
        return dom;

    };

    function openModal() {
        clearTimeout(a);

        if (!body.classList.contains('f-modal-is-open')) {
            // Modal template markup
            const modalMarkup = `
            <div class="f-modal" id="modal-player">
                <div class="f-modal-close"></div>
                <div class="f-modal-table">
                    <div class="f-modal-cell">
                        <div class="f-modal-content">
                            <div class="player">
                                <video controls playsinline data-poster="${this.getAttribute('data-video-poster')}">
                                    <source src="${this.getAttribute('data-video-src')}" type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            `;

            var modal = stringToHTML(modalMarkup); // Convert template markup to HTML

            body.appendChild(modal); // Add modal to DOM

            modalEl = document.querySelector('#modal-player'); // Select the modal
            modalClose = modalEl.children[0]; // Select the modal close button

            player = new Plyr(modalEl.querySelector('.player').children[0]); // Create Plyr

            // Begin opening modal
            modalEl.style.display = 'block';

            a = setTimeout(function () {
                body.classList.add('f-modal-is-open');
                modalEl.classList.add('f-modal-open');

                player.play();

                modalClose.addEventListener('click', closeModal, false);
            }, 50);

        }
    }

    function closeModal() {
        clearTimeout(b);

        player.pause(); // Pause Plyr on close

        // Begin modal removal
        body.classList.remove('f-modal-is-open');
        modalEl.classList.remove('f-modal-open');

        b = setTimeout(function () {
            modalEl.style.display = ''; // Hide modal visually
            modalEl.parentNode.remove(); // Remove modal from DOM
            player.destroy(); // Destroy Plyr instance
        }, 400);
    }

    element.forEach(el => el.addEventListener('click', openModal, false));
});

// Init Plyr
document.addEventListener('DOMContentLoaded', () => {
    const players = Array.from(document.querySelectorAll('#modal-player video')).map(p => new Plyr(p), {hideControls: true});
});

// Accordion menu
document.addEventListener('DOMContentLoaded', () => {
    let opened = null

    let a;

    const toggler = document.querySelectorAll('[data-accordion]')

    const toggleVisibility = e => {
        if (window.innerWidth <= 768) {
            e.classList.toggle('accordion-open')
            e.previousElementSibling.classList.toggle('accordion-is-open')

            clearTimeout(a);

            if (e.classList.contains('accordion-open')) {
                e.style.display = 'block'
                e.style.maxHeight = e.scrollHeight + 'px';
            } else {
                e.style.maxHeight = '';
                a = setTimeout(function () {
                    e.style.display = ''
                }, 600);
            }
        }
    }

    const checkIfOpened = e => {
        if (e.classList.contains('accordion-open')) {
            e.style.display = 'block'
            e.style.maxHeight = e.scrollHeight + 'px';
        }
    }

    toggler.forEach(el => checkIfOpened(el.nextElementSibling))

    const handleAccordion = e => {

        const clickedItem = e.parentElement.children[0].nextElementSibling

        toggleVisibility(clickedItem)

        if (!opened) {
            opened = clickedItem
        } else if (opened == clickedItem) {
            opened = null
        } else {
            toggleVisibility(opened)
            opened = clickedItem
        }

    }

    const handleClick = e => {

        if (e.target.dataset.accordion) {
            e.preventDefault();

            handleAccordion(e.target)
        }

    }

    document.addEventListener('click', handleClick)
});

// FAQ collapsible
document.addEventListener('DOMContentLoaded', () => {
    let el = document.querySelectorAll('.question');

    const toggle = e => {
        if (!e.target.parentNode.classList.contains('open')) {
            e.target.parentNode.classList.add('open')

            e.target.parentNode.children[1].style.maxHeight = e.target.parentNode.children[1].scrollHeight + 'px';
        } else {
            e.target.parentNode.classList.remove('open')

            e.target.parentNode.children[1].style.maxHeight = '';
        }
    }

    el.forEach(elm => elm.addEventListener('click', toggle, false));

    const checkIfOpened = e => {
        console.log(e);
        if (e.parentNode.classList.contains('open')) {
            e.parentNode.children[1].style.maxHeight = e.parentNode.children[1].scrollHeight + 'px';
        }
    }

    el.forEach(elm => checkIfOpened(elm))
});

// Reveal on scroll
document.addEventListener('DOMContentLoaded', () => {
    const options = {
        threshold: 0,
        rootMargin: '0px 0px -50px 0px',
    };

    var elementsArr = [
        'header',
        '.hero-content h1',
        '.hero-content p',
        '.hero-content .btn',
        '.teaser-content h2',
        '.teaser-content p',
        '.teaser-content .pretitle',
        '.teaser-content .btn',
        '.video-section',
        '.areas-of-focus h3',
        '.areas-of-focus .card',
        '.home-team-section .heading h2',
        '.home-team-section .heading p',
        '.meet-the-team',
        '.meet-the-team h3',
        '.meet-the-team p',
        '.meet-the-team .btn',
        '.swiper-team .member-slide',
        '.trusted-partners-section h3',
        '.trusted-partners-section .item',
        '.thoughts-section h2',
        '.thoughts-card',
        '.intro-content h1',
        '.intro-content .pretitle',
        '.intro-content p',
        '.intro-content ul li',
        '.team-bio-photo',
        '.team-bio-content .name',
        '.team-bio-content .position',
        '.team-bio-content .description',
        '.team-bio-content .btn',
        '.team-row h2',
        '.member-slide',
        '.swiper-case-studies',
        '.swiper-case-studies .case-content .category',
        '.swiper-case-studies .case-content .date',
        '.swiper-case-studies .case-content .title',
        '.swiper-case-studies .case-content .btn',
        '.case-study',
        '.form-property-search',
        '.properties-section h3',
        '.property',
        '.contact-details h1',
        '.contact-details .pretitle',
        '.contact-details a',
        '.contact-details p',
        '.contact-section .form-group',
        '.blog-header',
        '.blog-cover',
        '.blog-content h1',
        '.blog-content h2',
        '.blog-content h3',
        '.blog-content p',
        '.case-study-header .title',
        '.case-study-header .meta',
        '.case-study-cover',
        '.case-study-content h1',
        '.case-study-content h2',
        '.case-study-content h3',
        '.case-study-content p',
        '.outcome-scheme-td',
        // Images
        '.swiper-home',
        '.intro-wide-background',
    ]

    let itemDelay = .15;

    let itemLoad = 0;

    function onEntry(entry) {
        itemLoad = 0;

        entry.forEach((change, i) => {

            if (change.isIntersecting) {

                itemLoad++;

                gsap.to(change.target, {
                    opacity: 1,
                    translateY: 0,
                    ease: Expo.easeOut,
                    duration: 2,
                    delay: itemLoad * itemDelay,
                }).then(function () {
                    observer.unobserve(change.target);

                    change.target.style.transform = '';
                    change.target.style.opacity = '';
                    change.target.style.willChange = '';
                });

            }
        });
    }

    // instantiate a new Intersection Observer
    let observer = new IntersectionObserver(onEntry, options);

    // list of cards
    let elements = document.querySelectorAll(elementsArr);

    const setProperties = e => {
        e.style.transform = 'translate3d(0, 2rem, 0)';
        e.style.opacity = '0';
        e.style.willChange = 'transform, opacity';
    }

    // elementsGroup1.forEach(elm => setProperties1(elm))
    elements.forEach(elm => setProperties(elm))

    // loop through all elements
    // pass each element to observe method
    for (let elm of elements) {
        observer.observe(elm);
    }
});

// splitting.js
document.addEventListener('DOMContentLoaded', () => {
    const btns = document.querySelectorAll('.btn');

    for (let i = 0; i < btns.length; i++) {
        const btnText = btns[i].children[0]

        if (typeof btnText !== 'undefined' && btnText.matches('.btn-text')) {
            const textClone = btnText.children[0].cloneNode(true)

            textClone.classList.remove('btn-text-original')
            textClone.classList.add('btn-text-clone')

            btnText.append(textClone)

            const btnSpan = btns[i].children[0].querySelectorAll('span')

            const results = Splitting({
                target: btnSpan
            });

            gsap.set('.btn-text-clone .char', {
                yPercent: 100,
            });

            createHover(null, btns[i])
        }
    }

    let hover = false;

    function createHover(i, element) {
        let hoverTl = gsap.timeline({paused: true, reversed: true})

        hoverTl.to(element.querySelectorAll('.btn-text-original .char'), {
            duration: 0.6,
            stagger: 0.02,
            yPercent: -100,
            ease: Expo.easeOut
        }).to(element.querySelectorAll('.btn-text-clone .char'), {
            duration: 0.6,
            stagger: 0.02,
            yPercent: 0,
            ease: Expo.easeOut
        }, '<0.1')

        element.addEventListener('mouseover', handleIn, false)
        element.addEventListener('mouseleave', handleOut, false)

        function handleIn() {
            if (!hover) {
                hoverTl.reversed() ? hoverTl.play() : hoverTl.reverse();
            }
            hover = true;
        }

        function handleOut() {
            hover = false;
            hoverTl.reversed() ? hoverTl.play() : hoverTl.reverse();
        }
    }

});

//filter based on country & property type
// $(document).ready(function () {
//     $("#btnFilterSearch").on("click", function (e) {
//         e.preventDefault();
//         var country = $('#countrySelect').find("option:selected").val();
//         var property = $('#propertySelect').find("option:selected").val();
//         SearchPropery(country, property)
//     });
// });
// function SearchPropery(country, property) {
//     if (country.toLowerCase() == 'all' && property.toLowerCase() == 'all') {
//         $('#propertyResults').hide();
//     } else {
//         $('#propertyResults div:has(p)').each(function () {
//             var rowCountry = $.trim($(this).find('p:eq(0)').text());
//             var rowProperty = $.trim($(this).find('p:eq(1)').text());
//             if (country.toLowerCase() != 'all' && property.toLowerCase() != 'all') {
//                 if (rowCountry.toLowerCase() == country.toLowerCase() && rowProperty.toLowerCase() == property.toLowerCase()) {
//                     $(this).show();
//                 } else {
//                     $(this).hide();
//                 }
//             } else if ($(this).find('p:eq(0)').text() != '' || $(this).find('p:eq(0)').text() != '') {
//                 if (country != 'all') {
//                     if (rowCountry.toLowerCase() == country.toLowerCase()) {
//                         $(this).show();
//                     } else {
//                         $(this).hide();
//                     }
//                 }
//                 if (property != 'all') {
//                     if (rowProperty.toLowerCase() == property.toLowerCase()) {
//                         $(this).show();
//                     }
//                     else {
//                         $(this).hide();
//                     }
//                 }
//             }
//
//         });
//     }
// }

$("#select-language").change(function() {
    var option = $(this).find('option:selected');
    window.location.href = option.data("url");
});
